exports.components = {
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-404-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/pages/404.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-404-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-dev-404-page-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/pages/dev-404-page.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-dev-404-page-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-index-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/pages/index.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-index-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-search-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/pages/search.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-search-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-blog-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/blog.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-blog-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-events-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/events.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-events-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-faculty-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/faculty.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-faculty-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-news-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/news.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-news-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-past-events-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/past-events.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-past-events-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-resources-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/resources.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-resources-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-staff-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/staff.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-staff-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-stories-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/stories.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-stories-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-students-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/students.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-students-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-page-template-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/PageTemplate.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-page-template-js" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-resource-template-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/ResourceTemplate.js?export=default" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-resource-template-js" */)
}



exports.head = {
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-404-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/pages/404.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-404-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-dev-404-page-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/pages/dev-404-page.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-dev-404-page-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-index-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/pages/index.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-index-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-search-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/pages/search.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-pages-search-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-blog-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/blog.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-blog-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-events-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/events.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-events-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-faculty-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/faculty.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-faculty-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-news-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/news.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-news-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-past-events-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/past-events.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-past-events-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-resources-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/resources.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-resources-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-staff-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/staff.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-staff-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-stories-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/stories.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-stories-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-students-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/list-pages/students.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-list-pages-students-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-page-template-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/PageTemplate.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-page-template-jshead" */),
  "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-resource-template-js": () => import("./../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/templates/ResourceTemplate.js?export=head" /* webpackChunkName: "component---node-modules-dws-contributes-sitespro-gatsby-base-src-templates-resource-template-jshead" */)
}

